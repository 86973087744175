import * as React from "react"
//import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
//import Banner from "../components/banner"
import SinglePerson from "../components/singlePerson"
import Avatar from "boring-avatars"

const StaffPage = () => (
    <>
        {/* <Banner pageTitle="Staff" ></Banner> */}
        <div className="banner">
            <StaticImage src="../images/domestic-cat.jpg" className="h-96 mt-20 object-cover" />
        </div>
        <Layout>
            <Seo title="Il nostro staff" description="Il nostro personale è esperto e competente, sempre pronto per accoglierti. Un punto di riferimento per i vostri amici animali." />
            <h1 className="text-center mb-24">Il nostro staff pronto ad accoglierti</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 not-prose mb-24">
                <SinglePerson nome="Dott. Riccardo Loni" sendmail="mailto:riccardo@ambulatorioveterinariopisa.it" mail="riccardo@ambulatorioveterinariopisa.it">
                    <StaticImage src="../images/loni-img.jpg" placeholder="TracedSVG" aspectRatio={1} width={144} class="rounded-full" />
                </SinglePerson>

                <SinglePerson nome="Dott.ssa Federica Marcucci" sendmail="mailto:federica@ambulatorioveterinariopisa.it" mail="federica@ambulatorioveterinariopisa.it">
                    <StaticImage src="../images/marchucci-img.jpg" placeholder="TracedSVG" aspectRatio={1} width={144} class="rounded-full" />
                </SinglePerson>

                <SinglePerson nome="Dott. Andrea Lami" sendmail="mailto:andrea@ambulatorioveterinariopisa.it" mail="andrea@ambulatorioveterinariopisa.it">
                    <StaticImage src="../images/lami.jpg" placeholder="TracedSVG" aspectRatio={1} width={144} class="rounded-full" />
                </SinglePerson>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 not-prose mb-8">
                <SinglePerson nome="Dott.ssa Francesca Riggio" sendmail="mailto:francesca@ambulatorioveterinariopisa.it" mail="francesca@ambulatorioveterinariopisa.it">
                    {/* <StaticImage src="../images/loni-img.jpg" class="rounded-full aspect-square w-48" /> */}
                    <Avatar
                        size={144}
                        name="Maria Mitchell"
                        variant="bauhaus"
                        colors={["#FFAD08", "#EDD75A", "#73B06F", "#0C8F8F", "#405059"]}
                    />
                </SinglePerson>

                <SinglePerson nome="Dott. Dario Tolaini" sendmail="mailto:dario@ambulatorioveterinariopisa.it" mail="dario@ambulatorioveterinariopisa.it">
                    {/* <StaticImage src="../images/marchucci-img.jpg" class="rounded-full aspect-square w-48" /> */}
                    <StaticImage src="../images/tolaini.jpg" placeholder="TracedSVG" aspectRatio={1} width={144} class="rounded-full" />
                </SinglePerson>

                <SinglePerson nome="Dott.ssa Delia Gadducci" sendmail="mailto:delia@ambulatorioveterinariopisa.it" mail="delia@ambulatorioveterinariopisa.it">
                    {/* <StaticImage src="../images/lami-img.jpg" class="rounded-full aspect-square w-48" /> */}
                    <Avatar
                        size={144}
                        name="Biddy Mason"
                        variant="bauhaus"
                        colors={["#FFAD08", "#EDD75A", "#73B06F", "#0C8F8F", "#405059"]}
                    />
                </SinglePerson>

                <SinglePerson nome="Dott. Angelo Loverso" sendmail="mailto:angelo@ambulatorioveterinariopisa.it" mail="angelo@ambulatorioveterinariopisa.it">
                    {/* <StaticImage src="../images/lami-img.jpg" class="rounded-full aspect-square w-48" /> */}
                    <StaticImage src="../images/loverso.jpg" placeholder="TracedSVG" aspectRatio={1} width={144} class="rounded-full" />
                </SinglePerson>

                <SinglePerson nome="Dott.ssa Giulia Vaccà" sendmail="mailto:giulia@ambulatorioveterinariopisa.it" mail="giulia@ambulatorioveterinariopisa.it">
                    {/* <StaticImage src="../images/loni-img.jpg" class="rounded-full aspect-square w-48" /> */}
                    <Avatar
                        size={144}
                        name="Ellen Swallow"
                        variant="bauhaus"
                        colors={["#FFAD08", "#EDD75A", "#73B06F", "#0C8F8F", "#405059"]}
                    />
                </SinglePerson>

                <SinglePerson nome="Silvia Volpi">
                    {/* <StaticImage src="../images/marchucci-img.jpg" class="rounded-full aspect-square w-48" /> */}
                    <Avatar
                        size={144}
                        name="Phillis Wheatley"
                        variant="bauhaus"
                        colors={["#FFAD08", "#EDD75A", "#73B06F", "#0C8F8F", "#405059"]}
                    />
                </SinglePerson>

                <SinglePerson nome="Chiara Parisi">
                    {/* <StaticImage src="../images/lami-img.jpg" class="rounded-full aspect-square w-48" /> */}
                    <Avatar
                        size={144}
                        name="Emma Lazarus"
                        variant="bauhaus"
                        colors={["#FFAD08", "#EDD75A", "#73B06F", "#0C8F8F", "#405059"]}
                    />
                </SinglePerson>

                <SinglePerson nome="Iuri Di Gangi">
                    {/* <StaticImage src="../images/lami-img.jpg" class="rounded-full aspect-square w-48" /> */}
                    <Avatar
                        size={144}
                        name="Susan B"
                        variant="bauhaus"
                        colors={["#FFAD08", "#EDD75A", "#73B06F", "#0C8F8F", "#405059"]}
                    />
                </SinglePerson>

                <div className="xl:col-start-2">
                    <SinglePerson nome="Luca Benvenuti">
                        {/* <StaticImage src="../images/loni-img.jpg" class="rounded-full aspect-square w-48" /> */}
                        <Avatar
                            size={144}
                            name="Marian Anderson"
                            variant="bauhaus"
                            colors={["#FFAD08", "#EDD75A", "#73B06F", "#0C8F8F", "#405059"]}
                        />
                    </SinglePerson>
                </div>

                <SinglePerson nome="Martina Bertuccelli" >
                    {/* <StaticImage src="../images/marchucci-img.jpg" class="rounded-full aspect-square w-48" /> */}
                    <Avatar
                        size={144}
                        name="Henrietta Swan"
                        variant="bauhaus"
                        colors={["#FFAD08", "#EDD75A", "#73B06F", "#0C8F8F", "#405059"]}
                    />
                </SinglePerson>
            </div>
        </Layout>
    </>
)

export default StaffPage