import * as React from "react"

const SinglePerson = ({ children, nome, specs, mail, sendmail }) => (
    <div className="singlePerson flex flex-col items-center text-center">
        {children}
        <h3 className="mt-4 text-xl font-bold mb-2">{nome}</h3>
        {/* <span className="block text-sm uppercase">Specialista in:</span> */}
        {/* <p className="text-lg mb-4">{specs}</p> */}
        <a href={sendmail}>{mail}</a>
    </div>
)

export default SinglePerson